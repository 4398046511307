import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react'

function Packages(props) {
    const navigate = useNavigate();
    const showsRegisterPage = useCallback(() => navigate('/register', { replace: true }), [navigate]);

    return (
    <div className="packages">
        <div className="container">
            {
                !props.hideTitle &&
                <div className="rowTitle">
                    Packages
                </div>
            }
            {
                props.hideTitle &&
                <div style={{marginTop: "120px"}}>
                </div>
            }
            <div className="package-row">
                <div className="package">
                    <p className="package-title">Standard</p>
                    <p className="package-price">5.99€</p>
                    <div className="data">
                        <div className="number"><b>15</b> Monthly Giveaways</div>
                        <div className="number">Engagement-based Picker</div>
                        <div className="number">Tailored Winner Announcements</div>
                        <div className="number">Prize Redemption Integration</div>
                    </div>
                    <button className="first" onClick={() => {showsRegisterPage()}}>Get Started</button>
                </div>
            
                <div className="package featured">
                    <p className="package-title">Starter</p>
                    <p className="package-price">9.99€</p>
                    <div className="data">
                        <div className="number"><b>50</b> Monthly Giveaways</div>
                        <div className="number">Engagement-based Picker</div>
                        <div className="number">Tailored Winner Announcements</div>
                        <div className="number">Prize Redemption Integration</div>
                    </div>
                    <button className="first" onClick={() => {showsRegisterPage()}}>Get Started</button>
                </div>
            
                <div className="package">
                    <p className="package-title">Premium</p>
                    <p className="package-price">14.99€</p>
                    <div className="data">
                        <div className="number"><b>Unlimited</b> Monthly Giveaways</div>
                        <div className="number">Engagement-based Picker</div>
                        <div className="number">Tailored Winner Announcements</div>
                        <div className="number">Prize Redemption Integration</div>
                    </div>
                    <button className="first" onClick={() => {showsRegisterPage()}}>Get Started</button>
                </div>
            </div>
        </div>
    </div>
  );
}

export default Packages;