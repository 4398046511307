import '../Assets/LoginPage.css'
import loginIMG from '../Assets/images/login.png'
import google from '../Assets/images/google.png'
import { Link } from "react-router-dom"
import { useState, useCallback } from 'react'
import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {useNavigate} from 'react-router-dom';

const Login = (props) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const showEventsPage = useCallback(() => navigate('/profile', {replace: true}), [navigate]);

    function validateInput() {
        if (!email || !password) {
            MySwal.fire(<p>Please check all inputs</p>)
            return false;   
        }

        return true;
    }
    function login() {
        if (!validateInput()) return;

        axios({
            url: "http://116.202.121.130:5000/api/login",
            method: "POST",
            data: {email, password},
        })
        .then((res) => { 
            if (res?.data?.success) {
                MySwal.fire({
                    title: <strong>Login successful.</strong>,
                    html: <i>You have logged in successfully</i>,
                    icon: 'success'
                })
                localStorage.setItem('token', res.data.message);
                props.setToken(res.data.message)
                showEventsPage();
            } else {
                MySwal.fire({
                    title: <strong>Invalid credentials. Try resetting your password.</strong>,
                    html: <i>{ res?.data?.message ? res?.data?.message : 'Unknown Error'}</i>,
                    icon: 'error'
                })
            }
        })
        .catch((err) => { 
            MySwal.fire({
                title: <strong>Invalid credentials. Try resetting your password.</strong>,
                html: <i>{ err?.response?.data?.message ? err?.response?.data?.message : 'Unknown Error'}</i>,
                icon: 'error'
            })
            console.log(err)
        });
    }

    return ( 
        <section className='login-wrapper'>
            <div className="loginContainer container">
                <div className="loginLeft">
                    <div className="loginContent">
                        <p className='dont'>No Account? <Link to="/register">Register</Link></p>
                        <div className="loginText">
                            <p className="loginHead">Log in
                            </p>
                            <p className='support'>Welcome back! Enter your credentials</p>
                        </div>
                        
                        <div className="credentials">
                            <div className="credentialsBox">
                                <p className="credentialTitle">Email Address</p>
                                <input type="text" onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            <div className="credentialsBox">
                                <p className="credentialTitle">Password</p>
                                <input type="password" onChange={(event) => setPassword(event.target.value)} />
                            </div>
                        </div>
                        <button className="loginBtn" onClick={(event) => login(event)}>Login</button>

                    </div>
                </div>
                <div className="login-image">
                    <img src={loginIMG} alt="" />
                </div>

            </div>
            
        </section>
        
     );
}
 
export default Login;