import React from 'react';
import "../Assets/LandingPage.css"
import workers from '../Assets/images/workers.png'
import giveaway from '../Assets/images/giveaway.png'
import dataprivacy from '../Assets/images/data.png'
import fraud from '../Assets/images/fraud.png'
import system from '../Assets/images/system.png'
import shield from '../Assets/images/shield.svg'
import gift from '../Assets/images/gift.svg'
import book from '../Assets/images/book.svg'
import instagram from '../Assets/images/instagram.png'
import github from '../Assets/images/github.png'
import cloudflare from '../Assets/images/cloudflare.png'
import ig from "../Assets/icons/ig.svg"
import tt from "../Assets/icons/tiktok.svg"
import yt from "../Assets/icons/youtube.svg";
import Packages from '../Components/Packages.jsx';


import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react'

function LandingPage(props) {
    const navigate = useNavigate();
    const showsRegisterPage = useCallback(() => navigate('/register', { replace: true }), [navigate]);

    return (
        <React.Fragment>

            <section className="landing-page">
                <div className="home-content container">
                    <h1 className="main-text">
                        Spontaneous Thrills,<br />Fair Wins Unveiled
                    </h1>
                    <div className="box-column">
                        <div className="box">
                            <div className="box-img">
                                <img src={book} alt="" className="box-icon" />
                            </div>
                            <div>
                                <p className="box-title">Comprehensive System</p>
                                <p className="box-desc">Customized Picks with Condition Checks</p>
                            </div>
                        </div>
                        <div className="box">
                            <div className="box-img">
                                <img src={gift} alt="" className="box-icon" />
                            </div>
                            <div>
                                <p className="box-title">Serendipity Unleashed</p>
                                <p className="box-desc">Pure Luck in Every Selection</p>
                            </div>
                        </div><div className="box">
                            <div className="box-img">
                                <img src={shield} alt="" className="box-icon" />
                            </div>
                            <div>
                                <p className="box-title">Guardians of Fairness</p>
                                <p className="box-desc">Elevating Trust in Prize Draw Selections</p>
                            </div>
                        </div>
                    </div>
                    <img src={workers} className="landing3d" alt="" />
                </div>

                
                <div className="cta">
                    <div className="container">
                        <div className="cta-title">Our partners</div>
                        <div className="cta-row">
                            <div className="cta-img">
                                <img src={instagram} alt="" />
                            </div>
                            <div className="cta-img">
                                <img src={github} alt="" />
                            </div>
                            <div className="cta-img">
                                <img src={cloudflare} alt="" />
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className="features container">
                    <div className="features-txt">
                        <h1 className="support-text">
                            Your Random Winner Solution
                        </h1>
                        <p className="support-desc">
                            Discover a new era of unbiased winner selection with our cutting-edge solution, Our innovative technology ensures that every random winner chosen is a testament to fairness and authenticity. With us, you can confidently host giveaways, knowing that the spirit of chance and honesty guides each selection.
                        </p>
                    </div>
                    <div className="workers">
                        <img src={giveaway} alt="" className="workers-img" />
                    </div>
                </div>
                <div className="section white-full">
                    <div className="container">
                        <div className="landingRow spacer spacer-bottom">
                            <div className="landingRowLeft">
                                <p className="rowTitle">Our Commitment to Data Protection</p>
                                <p className="rowDesc">At our core, we uphold a steadfast commitment to safeguarding your privacy. Our company's unwavering policy ensures that your data is held in the highest regard, never to be shared or sold to third parties. With us, your information remains secure, reflecting our dedication to maintaining your trust and confidentiality.</p>
                                <button className="first" onClick={showsRegisterPage}>Get Started</button>
                            </div>
                            <div className="landingRowRight">
                                <img src={dataprivacy} alt="" />
                            </div>
                        </div>
                        <div className="reverse landingRow spacer spacer-bottom">
                            <div className="landingRowLeft">
                                <p className="rowTitle">Fraud Detection Mastery</p>
                                <p className="rowDesc">Experience peace of mind as we unveil our cutting-edge system designed to thwart fraudulent activities in giveaways. From identifying duplicate comments to unmasking fake profiles and bot-driven entries, our technology ensures the integrity of every contest. Embrace worry-free engagement, knowing that fair play prevails in all our endeavors.</p>
                                <button className="first" onClick={showsRegisterPage}>Get Started</button>
                            </div>
                            <div className="landingRowRight">
                                <img src={fraud} alt="" />
                            </div>
                        </div>
                        <div className="landingRow spacer spacer-bottom">
                            <div className="landingRowLeft">
                                <p className="rowTitle">Comprehensive Multi-Parameter System</p>
                                <p className="rowDesc">Experience the convenience of our all-inclusive system, offering dynamic selection based on multiple parameters. From ensuring fulfillment of all conditions to verifying actions like profile likes, we provide a seamless process tailored to your needs. Elevate your giveaways with precision and efficiency, leaving no detail unchecked.</p>
                                <button className="first" onClick={showsRegisterPage}>Get Started</button>
                            </div>
                            <div className="landingRowRight">
                                <img src={system} alt="" />
                            </div>
                        </div>
                    </div>

                </div>
                <Packages />
            </section>

        </React.Fragment>
    );
}

export default LandingPage;