import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import offers from "../logo.svg"
import logo from "../Assets/images/logo.png"
import close from "../Assets/icons/close.svg"
import {useNavigate} from 'react-router-dom';
import ig from "../Assets/icons/ig.svg"
import tt from "../Assets/icons/tiktok.svg"
import yt from "../Assets/icons/youtube.svg"
import hamburger from "../Assets/icons/hamburger.svg"
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "../Assets/Navbar.css"
import { useEffect } from 'react';


function Navbar(props) {
  const navigate = useNavigate();
  const showLandingPage = useCallback(() => navigate('/', {replace: true}), [navigate]);
  const showLandingLogout = useCallback(() => {
    navigate('/', {replace: true}) 
  }, [navigate]);

  const MySwal = withReactContent(Swal);

  function logout() {
    MySwal.fire({
      title: <strong>Logout successful.</strong>,
      html: <i>You have logged out successfully!</i>,
      icon: 'success'
    })
    props.setToken(null);
    showLandingLogout();
  }

  function navbarAnimation(){
    document.getElementById("hamburger").classList.toggle("move");
  }

  function parseJwt(token) {
    if (!token) 
      return {};
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  return (
    <nav className="navbar">
      <div id="hamburger" className="mobileNavbar move">
        <div className="mobileNavbarContent">

          <div className="hamburgerTop">
            <img src={logo} className="logo" onClick={showLandingPage} />
            <img src={close} onClick={navbarAnimation} className="close"/>
          </div>
          <div className="hamburgerMiddle">
              <div className="hamburgerItem">
              <Link to="/" className="nav-item">Home</Link>
                
              </div>
              {props.token && 
              <div className="hamburgerItem">
                  <Link to="/giveaway" className="nav-item">My Giveaway</Link>
              </div>
              }

              {props.token && 
              <div className="hamburgerItem">
                  <a className="nav-item" onClick={()=>{logout()}}>Logout</a>
              </div>
              }

              {!props.token && 
              <div className="hamburgerItem">
                  <Link to="/register" className="nav-item">Register</Link>
              </div>
              }

              {!props.token && 
              <div className="hamburgerItem">
                  <Link to="/login" className="nav-item">Log in</Link>
              </div>
              }

            </div>
            <div className="hamburgerBottom">
                        <a href="https://www.instagram.com/" target="_blank"><img src={ig} alt="" /></a>
                        <a href="https://www.tiktok.com/@" target="_blank"><img src={tt} id="tt" alt="" /></a>
                        <a href="https://www.youtube.com/@" target="_blank"><img src={yt} id="tt" alt="" /></a>
            </div>
        </div>
      </div>
      <div className="nav-content container">
        <div className="left">
          <img src={logo} className="logo" onClick={showLandingPage} />
        </div>
        <div className="middle desktop">
         
        </div>
        <div className="right">
          <div className="mobile">
          <img src={hamburger} onClick={navbarAnimation}/>

          </div>
          <div className="desktop">
          <Link to="/" className="nav-item">Home</Link>
          {props.token && <Link className="nav-item" to="/giveaway">My Giveaway</Link>}
          {props.token && <a  className="nav-item" onClick={()=>{logout()}}>Logout</a>}
          {!props.token && <Link className="nav-item" to="/register">Register</Link>}
          {!props.token && <Link className="first nav-cta nav-item"  to="/login">Login</Link>}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;