import React, { useState, useEffect } from 'react';
import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom'
import './App.css';
import Navbar from './Components/Navbar.jsx';
import LandingPage from './Views/LandingPage.jsx';
import Login from './Views/Login.jsx';
import Register from './Views/Register.jsx';
import Packages from './Components/Packages';

function App() {
  const [token, setToken] = useState(localStorage.getItem('token'))
  const [showItemModal, setShowItemModal] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [user, setUser] = useState(parseJwt(window.localStorage.getItem('token')))
  const [child, setChild] = useState(null)

  useEffect(() => {
    if (token) {
      setUser(parseJwt(token))
    }
  }, [token])

  function parseJwt(token) {
    if (!token) 
      return {};
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse(window.atob(base64));
  }

  return (
    <React.Fragment>
      <BrowserRouter>
        <Navbar token={token} setToken={setToken} />
        <Content user={user} setToken={setToken} setUser={setUser} setChild={setChild} child={child} />
      </BrowserRouter>
    </React.Fragment>
  );
}

function Content(props) {
  const [pinValidated, setPinValidated] = useState(false);

  const location = useLocation();

  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) setTransistionStage("fadeOut");
  }, [location, displayLocation]);

  return (
    <div className={`${transitionStage}`} onAnimationEnd={() => {if (transitionStage === "fadeOut") {setTransistionStage("fadeIn");setDisplayLocation(location);}}}>
      <Routes location={displayLocation}>
        <Route path="/" element={<LandingPage/>}/>
        <Route path="/login" element={<Login setToken={props.setToken}/>}/>
        <Route path="/register" element={<Register/>}/>
        <Route path="/packages" element={<Packages hideTitle={true}/>}/>


      </Routes>
    </div>
);
}

export default App;