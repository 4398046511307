import '../Assets/LoginPage.css'
import loginIMG from '../Assets/images/login.png'
import google from '../Assets/images/google.png'
import { Link } from "react-router-dom"
import { useState, useCallback } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import {useNavigate} from 'react-router-dom';


const Register = () => {
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const MySwal = withReactContent(Swal);
    const navigate = useNavigate();
    const showLoginPage = useCallback(() => navigate('/login', {replace: true}), [navigate]);
  
    function validateInput() {
        if (!firstName || !lastName || !email || !password || !confirmPassword) {
            MySwal.fire(<p>Please check all inputs</p>)
            return false;   
        }

        if (password != confirmPassword) {
            MySwal.fire(<p>Confirm Password and Password are different</p>)
            return false;   
        }


        return true;
    }

    function register() {
        if (!validateInput()) return;
    
        let name = `${firstName} ${lastName}`;
    
        axios({
            url: "http://116.202.121.130:5000/api/register",
            method: "POST",
            data: {email, name, password},
        })
        .then((res) => { 
            if (res?.data?.success) {
                MySwal.fire({
                    title: <strong>Account Created Successfully.</strong>,
                    html: <i>You have successfully created an account!</i>,
                    icon: 'success'
                })
                showLoginPage();
            } else {
                MySwal.fire({
                    title: <strong>Error Creating Account.</strong>,
                    html: <i>{ res?.data?.message ? res?.data?.message : 'Unknown Error'}</i>,
                    icon: 'error'
                })
            }
        })
        .catch((err) => { 
            MySwal.fire({
                title: <strong>Error Creating Account.</strong>,
                html: <i>{ err?.response?.data?.message ? err?.response?.data?.message : 'Unknown Error'}</i>,
                icon: 'error'
            })
            console.log(err)
        });
    }
    return ( 
        <div className='login-wrapper'>
            <div className="loginContainer container">
                <div className="loginLeft">
                    <div className="loginContent">
                        <p className='dont'>Already have an account? <Link to="/login">Log in</Link></p>
                        <div className="loginText">
                            <p className="loginHead">Register
                            </p>
                            <p className='support'>Welcome back! Enter your credentials.</p>
                        </div>
                        
                        <div className="credentials">
                            <div className="credentialsBoxRegister">
                                <div>
                                    <p className="credentialTitle">First Name</p>
                                    <input type="text" onChange={(event) => setFirstName(event.target.value)} />

                                </div>
                                <div>
                                    <p className="credentialTitle">Last Name</p>
                                    <input type="text" onChange={(event) => setLastName(event.target.value)} />

                                </div>
                            </div>
                            <div className="credentialsBox">
                                <p className="credentialTitle">Email Address</p>
                                <input type="text" onChange={(event) => setEmail(event.target.value)} />
                            </div>
                            <div id="credentialsBreak" className="credentialsBoxRegister">
                                <div>
                                    <p className="credentialTitle">Password</p>
                                    <input type="password" onChange={(event) => setPassword(event.target.value)} />

                                </div>
                                <div>
                                    <p className="credentialTitle">Confirm Password</p>
                                    <input type="password" onChange={(event) => setConfirmPassword(event.target.value)} />

                                </div>
                            </div>
                        </div>
                        <button className="loginBtn" onClick={(event) => register(event)}>Register</button>

                    </div>
                </div>
                {/* <div className="loginRight">
                    <img src={loginIMG} alt="" />
                </div> */}

            </div>
            <div className="login-image">
                <img src={loginIMG} alt="" />
            </div>
        </div>
     );
}
 
export default Register;